import logo from './assets/images/logo.svg';

const config = {
  authentication: {
    logo,
    providers: {
      saml: {
        enable: true,
        environments: {
          staging: {
            authUrl: '/users/auth/saml'
          },
          production: {
            authUrl: '/users/auth/saml'
          }
        }
      },
      developer: {
        enable: true,
        environments: {
          development: {
            authUrl: '/users/auth/developer'
          }
        }
      }
    },
  },
  displayEnvDisclaimer: {
    development: 'development',
    staging: 'staging',
  }
};

export default config;
